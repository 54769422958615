<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="信访维稳"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">信访维稳及政策宣讲</div>
        </el-row>
        <el-row>
          <el-col :span="4" class="title_view5">
            <div class="firstTitletStyle">
              信访维稳
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col>
                <div class="leftTitletStyle">是否有维稳预案（是/否）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">来访</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">批次（批）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">人次（人）</div>
                </el-row>
              </el-col>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">群体性事件发生次数（次）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle1">信访反映的主要问题</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle1">信访问题的处理结果</div>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="4" class="title_view5">
            <div class="firstTitletStyle">政策宣讲</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">移民干部政策培训</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="8" class="title_view4">
                    <div class="leftTitletStyle">省</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">批次（批）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">人次（人）</div>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8" class="title_view4">
                    <div class="leftTitletStyle">市</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">批次（批）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">人次（人）</div>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8" class="title_view4">
                    <div class="leftTitletStyle">县</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">批次（批）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">人次（人）</div>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">移民实用技术培训</div>
              </el-col>

              <el-col :span="16">
                <el-row>
                  <el-col :span="8" class="title_view4">
                    <div class="leftTitletStyle">农业技术培训</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">批次（批）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">人次（人）</div>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8" class="title_view4">
                    <div class="leftTitletStyle">就业技术培训</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">批次（批）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">人次（人）</div>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8" class="title_view4">
                    <div class="leftTitletStyle">其他技术培训</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">批次（批）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">人次（人）</div>
                    </el-row>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="8" class="title_view4">
                    <div class="leftTitletStyle">移民技能培训</div>
                  </el-col>
                  <el-col :span="16">
                    <el-row>
                      <div class="leftTitletStyle">批次（批）</div>
                    </el-row>
                    <el-row>
                      <div class="leftTitletStyle">人次（人）</div>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('visitingBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('visitingCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('groupEventCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTitletStyle1"></div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle1"></div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('trainingProvinceBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('trainingProvinceCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('trainingCityBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('trainingCityCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('trainingCountyBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('trainingCountyCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('agriculturalTechTrainingBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('agriculturalTechTrainingCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('employmentTechTrainingBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('employmentTechTrainingCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('otherTechTrainingBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('otherTechTrainingCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrationSkillTrainingBatch') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrationSkillTrainingCount') }}</div>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 300px;" v-for="(col, index) in listData" :key="index">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>
                  </el-col>
                  <el-col>
                    <template v-for="key1 in Object.keys(col)">
                      <el-row v-if="key1 == 'countyId'">
                        <div class="contentStyle">
                          <el-cascader  :append-to-body="false"  
                            v-if="type == 'add' || type == 'edit'"
                            v-model="col[key1]"
                            size="mini"
                            :options="levalRegion"
                            :props="treeProps"
                            @change="handleChange($event, index)"
                            :show-all-levels="false"
                            placeholder="请选择"
                          >
                          </el-cascader>
                          <div v-else>
                            {{ col['county'] }}
                          </div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'stabilityPlanTrue'">
                        <div class="contentStyle">
                          <div v-if="type == 'add' || type == 'edit'">
                            <el-radio v-model="col['stabilityPlanTrue']" :label="1">是</el-radio>
                            <el-radio v-model="col['stabilityPlanTrue']" :label="0">否</el-radio>
                          </div>
                          <div v-else>{{ newarr[col['stabilityPlanTrue']] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'visitingBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'visitingCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'groupEventCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'mainComplaint'">
                        <div class="contentStyle1">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            type="textarea"
                            resize="none"
                            :rows="10"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'complaintResolution'">
                        <div class="contentStyle1">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            type="textarea"
                            resize="none"
                            :rows="10"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == '' ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'trainingProvinceBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'trainingProvinceCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'trainingCityBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'trainingCityCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'trainingCountyBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'trainingCountyCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'agriculturalTechTrainingBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'agriculturalTechTrainingCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'employmentTechTrainingBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'employmentTechTrainingCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'otherTechTrainingBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'otherTechTrainingCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'immigrationSkillTrainingBatch'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>

                      <el-row v-if="key1 == 'immigrationSkillTrainingCount'">
                        <div class="contentStyle">
                          <el-input
                            v-if="type == 'add' || type == 'edit'"
                            size="mini"
                            type="number"
                            min="0"
                            v-model="col[key1]"
                            placeholder="请输入"
                          >
                          </el-input>
                          <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                        </div>
                      </el-row>
                    </template>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'
import {
  _AdvocacyAdd,
  _AdvocacyInfo,
  _AdvocacyEdit
} from '@/api/modular/postImmigrationService/policy/policyPreaching.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      options: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', checkStrictly: true },
      newarr: ['否', '是']
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 300 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    calculateTheTotal(name) {
      let sum = 0
      for (let i = 0; i < this.listData.length; i++) {
        let ele = this.listData[i]
        sum += Number(ele[name])
      }
      return sum
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },
    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },
    btnClose() {
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year, //年份
            countyId: null, //县（市区）
            county: '', //县（市区）
            stabilityPlanTrue: null, //维稳预案（是）
            stabilityPlanFalse: null, //维稳预案 （是）
            visitingBatch: null, //来访批次
            visitingCount: null, //来访人次
            groupEventCount: null, //群体性事件发生次数
            mainComplaint: '', //信访反映的主要问题
            complaintResolution: '', //信访问题处理结果

            trainingProvinceBatch: null, //移民干部政策培训省批次
            trainingProvinceCount: null, //移民干部政策培训省批人次
            trainingCityBatch: null, //移民干部政策培训市批次
            trainingCityCount: null, //移民干部政策培训市人次
            trainingCountyBatch: null, //移民干部政策培训县批次
            trainingCountyCount: null, //移民干部政策培训县人次
            agriculturalTechTrainingBatch: null, //农业技术培训批次
            agriculturalTechTrainingCount: null, //农业技术培训批人次
            employmentTechTrainingBatch: null, //就业技术培训批次
            employmentTechTrainingCount: null, //就业技术培训人次
            otherTechTrainingBatch: null, //其他技术培训批次
            otherTechTrainingCount: null, //其他技术培训人次
            immigrationSkillTrainingBatch: null, //移民技能培训批次
            immigrationSkillTrainingCount: null, //移民技能培训人次
            countyPids: '' //pids
          }

          if (this.listData.length) {
            let flag = this.listData.every(ele => ele.countyId)
            if (flag) {
              this.listData.splice(this.listData.length, 0, obj)
            } else {
              this.$message.error('请选择县（市区）')
            }
          } else {
            this.listData.splice(this.listData.length, 0, obj)
          }
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    refreshTable() {
      this.getBasicInfo()
    },

    submitForm() {
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          stabilityPlanFalse: ele.stabilityPlanTrue == 0 ? 1 : 0
        }
      })
      console.log(listData, '===submitForm===')

      let flag = listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _AdvocacyAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
        case 'edit':
          if (flag) {
            _AdvocacyEdit(listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }

          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _AdvocacyInfo(params).then(res => {
        if (res.code == 200) {
          let arr = [res.data]
          this.listData = this.formateResData(arr)

          console.log(this.listData, '==============_AdvocacyInfo==============')
        }
      })
    },
    formateResData(arr) {
      return arr.map(ele => {
        return {
          year: ele.year, //年份
          countyId: ele.countyId, //县（市区）
          county: ele.county, //县（市区）
          stabilityPlanTrue: ele.stabilityPlanTrue ?? 0, //维稳预案（是）
          stabilityPlanFalse: ele.stabilityPlanFalse ?? 0, //维稳预案 （是）
          visitingBatch: ele.visitingBatch, //来访批次
          visitingCount: ele.visitingCount, //来访人次
          groupEventCount: ele.groupEventCount, //群体性事件发生次数
          mainComplaint: ele.mainComplaint, //信访反映的主要问题
          complaintResolution: ele.complaintResolution, //信访问题处理结果

          trainingProvinceBatch: ele.trainingProvinceBatch, //移民干部政策培训省批次
          trainingProvinceCount: ele.trainingProvinceCount, //移民干部政策培训省批人次
          trainingCityBatch: ele.trainingCityBatch, //移民干部政策培训市批次
          trainingCityCount: ele.trainingCityCount, //移民干部政策培训市人次
          trainingCountyBatch: ele.trainingCountyBatch, //移民干部政策培训县批次
          trainingCountyCount: ele.trainingCountyCount, //移民干部政策培训县人次
          agriculturalTechTrainingBatch: ele.agriculturalTechTrainingBatch, //农业技术培训批次
          agriculturalTechTrainingCount: ele.agriculturalTechTrainingCount, //农业技术培训批人次
          employmentTechTrainingBatch: ele.employmentTechTrainingBatch, //就业技术培训批次
          employmentTechTrainingCount: ele.employmentTechTrainingCount, //就业技术培训人次
          otherTechTrainingBatch: ele.otherTechTrainingBatch, //其他技术培训批次
          otherTechTrainingCount: ele.otherTechTrainingCount, //其他技术培训人次
          immigrationSkillTrainingBatch: ele.immigrationSkillTrainingBatch, //移民技能培训批次
          immigrationSkillTrainingCount: ele.immigrationSkillTrainingCount, //移民技能培训人次
          countyPids: ele.countyPids, //pids
          id: ele.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
